import React from "react";
import { Grid } from "@nodeme/grid-react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IProps {
  id: number;
  artist?: string;
  title?: string;
  thumb?: string | null;
  lot?: number;
  onClick: () => void;
}
export interface IState {}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  thumb: {
    height: "220px",
    width: "calc(100% + 32px)",
    margin: "-16px -16px 16px -16px",
    backgroundColor: theme.palette.get("lightGrey"),
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    paddingTop: "64px",
    boxSizing: "border-box",
    display: "block",
    backgroundPosition: "center",
    backgroundImage: (props: IProps) =>
      props.thumb ? `url("${props.thumb}")` : "none",
  },
  card: {
    cursor: "pointer",
  },
});

export default function ArtworkCard(props: IProps) {
  const classes = buildClasses(style, { ...props });

  return (
    <div className={classes.card} onClick={props.onClick}>
      <Block>
        <div className={classes.thumb}>
          {!props.thumb && <FontAwesomeIcon icon={faSpinnerThird} spin />}
        </div>
        <Grid>
          <Grid item>
            <Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  <span className={classNames([classes.line, classes.artist])}>
                    {props.title}
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  <span className={classNames([classes.line, classes.title])}>
                    {props.title}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Block>
    </div>
  );
}
