import React, { useState } from "react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import ArtworkBid from "./ArtworkBid";

export interface IProps {
  id: number;
  artist?: string;
  title?: string;
  mockup?: string | null;
  thumb?: string | null;
  lot?: number;
  description?: string;
  category?: string;
  technique?: string;
  artistDescription?: string;
  width?: number;
  height?: number;
  depth?: number;
  price?: number;
  year?: number;
  onClose: () => void;
}
export interface IState {}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  wrapper: {
    position: "relative",
  },
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  img: {
    maxWidth: "100%",
  },
  pendingMockup: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  subHeading: {
    color: theme.palette.get("primary"),
    margin: "0px 0px 8px 0px",
  },
  data: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
  price: {
    fontWeight: 400,
  },
  bidContainer: { textAlign: "center" },
});

export default function ArtworkDetails(props: IProps) {
  const classes = buildClasses(style, { ...props });
  const [bidding, setBidding] = useState(false);

  return (
    <Block>
      <div className={classes.wrapper}>
        <Grid
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0, bottom: 0 }}
          alignItems="flex-end"
        >
          {props.mockup !== null && (
            <Grid item lg={2}>
              {typeof props.mockup === "undefined" && (
                <div className={classes.pendingMockup}>
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                </div>
              )}
              {Boolean(props.mockup) && (
                <img className={classes.img} alt="mockup" src={props.mockup} />
              )}
            </Grid>
          )}
          <Grid item lg={"auto"}>
            {props.thumb !== null && (
              <img alt="thumb" className={classes.img} src={props.thumb} />
            )}
          </Grid>
          <Grid item lg="rest-fixed">
            <div>
              <Grid root={{ bottom: 0 }}>
                <Grid item>
                  <Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.artist])}
                        >
                          {props.title}
                        </span>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.title])}
                        >
                          {props.title}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid spacing={16} vertical root={{ bottom: 0, left: 0, right: 0 }}>
              {/*               <Grid item lg={10}>
                <span className={classes.data}>{props.year}</span>
              </Grid> */}
              {!bidding && (
                <Grid item>
                  <h6 className={classes.subHeading}>INFOS</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.description || "",
                    }}
                  />
                </Grid>
              )}
              {bidding && (
                <Grid item>
                  <ArtworkBid
                    id={props.id}
                    artist={props.artist || ""}
                    title={props.title || ""}
                    onClose={() => {
                      setBidding(false);
                    }}
                  />
                </Grid>
              )}
              {!bidding && (
                <Form onSubmit={() => {}}>
                  <Grid
                    spacing={16}
                    vertical
                    root={{ left: 0, right: 0, bottom: 0 }}
                    justify="flex-end"
                  >
                    <Button item xs="120px" onClick={props.onClose}>
                      Schließen
                    </Button>

                    <Button
                      item
                      xs="120px"
                      primary
                      onClick={(e) => {
                        e.preventDefault();
                        setBidding(true);
                      }}
                    >
                      Interesse
                    </Button>
                  </Grid>
                </Form>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Block>
  );
}
