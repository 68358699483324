import React from "react";
import { Grid } from "@nodeme/grid-react";
import PendingBlock from "./Shared/PendingBlock";
import Artwork, { IProps as IArtwork } from "./Artwork";
import ErrorBlock from "./Shared/ErrorBlock";
import Block from "./Shared/Block";

const categories = [
  "Gemälde",
  "Grafik Multiple",
  "Keramiken",
  "Multimedia",
  "Skulptur Volumen",
  "Zeichnung/Aquarell",
  "Antiquitäten",
  "Architektonische Elemente",
  "Design",
  "Fotografie",
  "Gegenstände",
  "Keramiken-Glas",
  "Lampen",
  "Miniatur",
  "Mobiliar",
  "Performing Art",
  "Teppiche",
];

export interface IProps {}
export interface IState {
  data: IArtwork[];
  pending: boolean;
  unknownError: boolean;
}

export default class Artworks extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      pending: false,
      unknownError: false,
    };
    this.load = this.load.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/public/dfi"
      ).then((response) => response.json());
      const data: IState["data"] = received
        .sort((a: any, b: any) => {
          const aNr = parseInt((a.data.description || "").split(" #")[0]) || 0;
          const bNr = parseInt((b.data.description || "").split(" #")[0]) || 0;
          return aNr - bNr;
        })
        .map((item: any, index: number) => {
          return {
            id: item.data.id,
            artist: item.data.name,
            title: item.data.title,
            category: categories[item.data.category - 1],
            technique: item.data.techniqueName,
            artistDescription: item.data.artistDescription,
            description: item.data.description,
            active: false,
            width:
              typeof item.data.width === "string" ||
              typeof item.data.width === "number"
                ? parseInt(item.data.width)
                : undefined,
            height:
              typeof item.data.height === "string" ||
              typeof item.data.height === "number"
                ? parseInt(item.data.height)
                : undefined,
            depth:
              typeof item.data.depth === "string" ||
              typeof item.data.depth === "number"
                ? parseInt(item.data.depth)
                : undefined,
            price: item.data.sellingPrice,
            thumbExtension: item.data.originalThumbExtension,
            thumbHeight: item.data.originalThumbHeight,
            thumbId: item.data.originalThumbId,
            thumbName: item.data.originalThumbName,
            thumbUpdateDate: item.data.originalThumbUpdateDate,
            thumbWidth: item.data.originalThumbWidth,
            year: item.data.year,
            lot: index + 1,
          };
        });

      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }

  toggleActive(lot?: number) {
    const newState = { ...this.state };
    if (lot && newState.data[lot - 1]) {
      newState.data[lot - 1].active = !newState.data[lot - 1].active;
      this.setState(newState);
    }
  }

  render() {
    if (this.state.unknownError)
      return <ErrorBlock text="Werke werden geladen..." />;
    if (this.state.pending)
      return <PendingBlock text="Werke werden geladen..." />;
    return (
      <Grid spacing={16} vertical>
        <Grid item>
          <Block>
            <h3 style={{ fontWeight: 400, color: "#246cb5" }}>
              Willkommen bei der Deutschen Fonds-Immobilien AG
            </h3>
            <p>
              Dies ist unser zukünftiger Webauftritt.
              <br />
              Wir bieten dem interessierten Anleger geschlossene Immobilienfonds
              in unterschiedlichen Ausrichtungen an.
              <br /> Wenn Sie eines oder mehrere der nachstehenden Portfolios
              anklicken, senden wir Ihnen gerne weitere Unterlagen zu den
              gewählten Fonds aus.
            </p>
            <p>
              Ihre <b>DFI</b>
            </p>
          </Block>
        </Grid>
        {this.state.data.map((artwork: IArtwork, index: number) => (
          <Grid
            key={"artwork-" + artwork.id}
            item
            sm={artwork.active ? 10 : 5}
            md={artwork.active ? 10 : 10 / 3}
          >
            <Artwork
              active={artwork.active}
              id={artwork.id}
              lot={artwork.lot}
              artist={artwork.artist}
              title={artwork.title}
              category={artwork.category}
              technique={artwork.technique}
              width={artwork.width}
              height={artwork.height}
              depth={artwork.depth}
              thumbExtension={artwork.thumbExtension}
              thumbHeight={artwork.thumbHeight}
              thumbWidth={artwork.thumbWidth}
              thumbId={artwork.thumbId}
              thumbName={artwork.thumbName}
              thumbUpdateDate={artwork.thumbUpdateDate}
              description={artwork.description}
              artistDescription={artwork.artistDescription}
              year={artwork.year}
              price={artwork.price}
              toggle={this.toggleActive}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
