import React from "react";
import classnames from "classnames";
import { Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/pro-light-svg-icons/faHouse";
export interface IProps extends RouteComponentProps<{}> {
  onClick?: () => void;
}

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  link: {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: 100,
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      padding: "0px 6px",
      margin: "4px 0px",
      display: "inline-block",
    },
  },
  active: {
    color: "#fff",
    fontWeight: 400,
  },
  icon: {
    marginRight: "4px",
  },
  navigation: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      width: "100%",
      height: "100%",
      left: "0",
      backgroundColor: "#000",
      zIndex: 999,
    },
  },
});

function Navigation(props: IProps) {
  const classes = buildClasses(styles, props);
  const { pathname } = props.location;
  return (
    <nav className={classes.navigation}>
      <Grid spacing={24} vertical>
        <Grid item md="auto">
          <Link
            className={classnames([
              classes.link,
              pathname === "/" && classes.active,
            ])}
            to="/"
            onClick={props.onClick}
          >
            <FontAwesomeIcon className={classes.icon} icon={faHouse} />{" "}
            Immobilien
          </Link>
        </Grid>
      </Grid>
    </nav>
  );
}

export default withRouter(Navigation);
